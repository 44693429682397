import { Controller } from 'react-hook-form'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { FormInputProps } from '@/components/FormComponents/FormInputProps'

interface Props extends FormInputProps {
  helperText?: TextFieldProps['helperText']
  slotProps?: TextFieldProps['slotProps']
}
export const FormInputText = ({
  name,
  control,
  label,
  'data-cy': dataCy,
  color,
  placeholder,
  InputProps,
  type,
  multiline,
  className,
  isDisabled,
  autoFocus = false,
  autocomplete,
  minRows,
  helperText,
  slotProps,
  ...props
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          type={type}
          helperText={error ? error.message : helperText}
          error={!!error}
          onChange={onChange}
          multiline={multiline}
          maxRows={multiline ? 10 : 1}
          minRows={minRows}
          value={value || ''}
          label={label}
          color={color}
          data-cy={dataCy}
          className={className}
          disabled={isDisabled}
          placeholder={placeholder}
          autoFocus={autoFocus}
          autoComplete={autocomplete}
          {...props}
          slotProps={{
            input: { ...InputProps },
            inputLabel: {
              style: { color: '#111617' },
            },
            ...slotProps,
          }}
          sx={{
            '&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                borderColor: '#111617',
              },
          }}
        />
      )}
    />
  )
}
