import config from '@/lib/config'
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile'
import { MutableRefObject } from 'react'

const Recaptcha = ({
  reference,
  onSuccess,
  onExpire,
}: {
  reference: MutableRefObject<TurnstileInstance | undefined>
  onSuccess: (token: string) => void
  onExpire: () => void
}) => {
  return (
    <>
      {config.NEXT_PUBLIC_CLOUDFLARE_SITE_KEY && (
        <Turnstile
          className="!h-auto !w-full"
          ref={reference}
          onSuccess={onSuccess}
          onExpire={onExpire}
          onError={() => {}}
          options={{
            size: 'flexible',
            refreshExpired: 'manual',
            theme: 'light',
          }}
          siteKey={config.NEXT_PUBLIC_CLOUDFLARE_SITE_KEY}
        />
      )}
    </>
  )
}
export type RecaptchaRef = TurnstileInstance
export default Recaptcha
