import { FeatureFlagEnum } from '@/components/FeatureFlags/FeatureFlagEnum'
import { featureFlags } from '@/components/FeatureFlags/utils'

/**
 * Method used for server components
 */
export const GetServerFeatureToggle = () => {
  let enabledFeatures: FeatureFlagEnum[] = featureFlags;

  const enableFeature = (featureName: FeatureFlagEnum) => {
    enabledFeatures = [...enabledFeatures, featureName]
  }

  const disableFeature = (featureName: FeatureFlagEnum) => {
    enabledFeatures = enabledFeatures.filter((feature) => feature !== featureName)
  }

  const isEnabled = (featureName: FeatureFlagEnum) => {
    return enabledFeatures.includes(featureName)
  }
  return [isEnabled, enableFeature, disableFeature] as [
    (featureName: FeatureFlagEnum) => boolean,
    (featureName: FeatureFlagEnum) => void,
    (featureName: FeatureFlagEnum) => void,
  ]
}