import { FeatureToggleContext } from '@/components/FeatureFlags/FeatureToggleContext'
import { useContext } from 'react'
import { FeatureFlagEnum } from '@/components/FeatureFlags/FeatureFlagEnum'

export const useFeatureToggle = () => {
  const { enabledFeatures, enableFeature, disableFeature } =
    useContext(FeatureToggleContext)

  const isEnabled = (featureName: FeatureFlagEnum) => {
    return enabledFeatures.includes(featureName)
  }

  return [isEnabled, enableFeature, disableFeature] as [
    (featureName: FeatureFlagEnum) => boolean,
    (featureName: FeatureFlagEnum) => void,
    (featureName: FeatureFlagEnum) => void,
  ]
}
