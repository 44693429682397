'use client'
import { FormInputProps } from '@/components/FormComponents/FormInputProps'
import { useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import { FormInputText } from '@/components/FormComponents/FormInputText'

export const FormInputPassword = ({
  name,
  control,
  label,
  'data-cy': dataCy,
  color,
  placeholder,
  InputProps,
  type,
  autocomplete,
  ...props
}: FormInputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }
  return (
    <FormInputText
      type={showPassword ? 'text' : 'password'}
      name={name}
      control={control}
      label={label}
      data-cy={dataCy}
      color={color}
      placeholder={placeholder}
      autocomplete={autocomplete}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
